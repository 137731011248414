import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    "init": Boolean,
    "format": String,
    "minDate": String,
    "maxDate": String,
    "daysOfWeekDisabled": Array,
    "disableDays": Array
  }

  connect() {
    if (this.hasFormatValue)
      $(this.element).datetimepicker('format', this.formatValue)

    if (this.hasMinDateValue)
      $(this.element).datetimepicker('minDate', this.minDateValue)

    if (this.hasMaxDateValue)
      $(this.element).datetimepicker('maxDate', this.maxDateValue)

    if (this.hasDisableDaysValue)
      $(this.element).datetimepicker('disabledDates', this.disableDaysValue)

    /*
    / Keep initValue false until first datetimepicker initialization is done
    / b/c it emits change.datetimepicker event.
    / Otherwise the cart form gets resubmitted in cart#updateWillDeliverAtDate
    */
    this.initValue = true
  }
}
