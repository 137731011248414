import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "pane"]
  isLocked = false

  nextTab() {
    const nextElm = $(".shop-page-tab-contents.active").parent().next("li").find('a');
    const event = new MouseEvent('click', { bubbles: true, cancelable: true });

    if (nextElm[0] != null) {
      nextElm[0].dispatchEvent(event);
      nextElm[0].scrollIntoView({block: 'nearest', behavior: "smooth"});
    }
  }

  prevTab() {
    const prevElm = $(".shop-page-tab-contents.active").parent().prev("li").find("a");
    const event = new MouseEvent('click', { bubbles: true, cancelable: true });

    if (prevElm[0] != null) {
      prevElm[0].dispatchEvent(event);
      prevElm[0].scrollIntoView({block: 'nearest', behavior: "smooth"});
    }
  }

  changeTab(event) {
    event.preventDefault();

    // 処理の重複を防ぐ
    if (this.isLocked) return;
    this.isLocked = true;

    this.tabTargets.forEach(tab => tab.classList.remove("active"));
    this.paneTargets.forEach(pane => pane.classList.remove("show", "active"));

    event.currentTarget.classList.add("active");

    const targetPane = document.querySelector(event.currentTarget.getAttribute("href"));
    targetPane.classList.add("show", "active");

    setTimeout(() => {
      this.isLocked = false;
    }, 100);
  }
}
