import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "counter", "deleteLink"];
  static values = { quantity: Number }

  timeoutId = null;

  connect() {
    const getCartItemDeleteModalControllerEvent = new CustomEvent('getCartItemDeleteModalController', { detail: {
      setController: (controller)=>{
        this.cartItemDeleteModalModal=controller;
        },
      onEnd: (ret, cart_item_controller)=>{
        this.cartItemDeleteModalModalEnd(ret, cart_item_controller);
        }
    }});
    window.dispatchEvent(getCartItemDeleteModalControllerEvent);

    this.previousNum = this.counterTarget.value;
  }

  change() {
    if (this.counterTarget.value > 0) {
      if (this.quantityValue >= this.counterTarget.value) {
        this.previousNum = this.counterTarget.value;
      } else {
        this.counterTarget.value = this.previousNum;
      }
      Rails.fire(this.element, 'submit');
    } else {
      this.counterTarget.value = this.previousNum;
      this.delete();
    }
  }

  increment() {
    let count = this.counterTarget.value;
    if (this.quantityValue > count) {
      clearTimeout(this.timeoutId);
      count++;
      this.counterTarget.value = count;
      this.previousNum = this.counterTarget.value;
      this.timeoutId = setTimeout(() => {
        Rails.fire(this.element, 'submit');
      }, 400);
    }
  }

  decrement() {
    let count = this.counterTarget.value;
    count--;
    if (count < 1) {
      this.delete();
    } else {
      clearTimeout(this.timeoutId);
      this.counterTarget.value = count;
      this.previousNum = this.counterTarget.value;
      this.timeoutId = setTimeout(() => {
        Rails.fire(this.element, 'submit');
      }, 400);
    }
  }

  cartItemDeleteModalModalEnd(canDelete, cart_item_controller) {
    if (canDelete) {
      Rails.fire(cart_item_controller.deleteLinkTarget, 'click');
    }
  }
  delete() {
    this.cartItemDeleteModalModal.openModal(this);
  }
}
